import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels, fontSizes } from '../../../tilli-components/src/components/styledComponents';

const DayContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 5px;
  border: 1px solid ${colors.navy};
  color: ${props => props.isSelected ? colors.white : colors.navy};
  background: ${props => props.isSelected ? colors.navy : colors.white};
  ${props => props.isCurrent && 'transform: scale(1.3);'}
  cursor: pointer;
  transition: all 0.3s ease;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: scale(1.3);
    }
  }
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50px;
    height: 50px;
  }
`;

const DayName = styled.div`
  padding-top: 5px;
  font-size: 16px;
  font-family: Gotham;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
  }
`;

const DayNumber = styled.div`
  font-size: ${fontSizes.x2l};
  font-family: Gotham;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
  }
`;

const Day = ({ day, isSelected, isCurrent, selectDay }) => (
  <DayContainer
    small
    isSelected={isSelected}
    isCurrent={isCurrent}
    key={day.format('dd D')}
    onClick={() => selectDay(day)}
  >
    <DayName>{day.format('ddd')}</DayName>
    <DayNumber>{day.format('D')}</DayNumber>
  </DayContainer>
);

Day.propTypes = {
  day: PropTypes.shape({}).isRequired,
  isSelected: PropTypes.bool,
  isCurrent: PropTypes.bool,
  selectDay: PropTypes.func.isRequired,
};

Day.defaultProps = {
  isSelected: false,
  isCurrent: false,
};

export default Day;
