import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toTwoDigitsOrMore } from '../services/formatting';
import { Button, margins, colors, fontSizes, mobileThresholdPixels } from './styledComponents';

const BlockContainer = styled.div`
  background-color: ${colors.lightGrey};
  width: 280px;
  text-align: center;
  overflow: hidden;
  margin-bottom: ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 165px;
    max-width: calc(50% - 10px);
  }
`;

const BlockSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px ${margins.s} ${margins.s} ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.xs};
  }
`;

const Img = styled.img`
  width: inherit;
`;

const StyledButton = styled(Button)`
  font-size: ${fontSizes.s};
  min-width: 0px;
  min-height: 0px;
  padding: 0px;
  width: 134px;
  height: 44px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const CounterRemote = styled(Button)`
  min-width: 0px;
  min-height: 0px;
  padding: 0px;
  width: 44px;
  height: 44px;
  border-width: 1px;

  &:hover{
    transform: none;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 2px 16px 0px;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const TextContainer = styled.div`
  font-family: Roboto;
  text-align: left;
  padding-top: ${margins.s};
  ${props => props.bold && 'font-weight: bold'};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const ClothComponent = ({ name, image, quantity, addCloth, removeCloth, submitCloth }) => (
  <BlockContainer>
    <Img src={image} alt={name} />
    <BlockSubContainer>
      <TextContainer>{name}</TextContainer>
      <TextContainer bold>{toTwoDigitsOrMore(quantity)}</TextContainer>
    </BlockSubContainer>
    <BlockSubContainer>
      <CounterRemote onClick={() => (quantity > 0) && removeCloth()}>-</CounterRemote>
      <CounterRemote onClick={() => addCloth()}>+</CounterRemote>
      <StyledButton navy onClick={submitCloth}>Ok</StyledButton>
    </BlockSubContainer>
  </BlockContainer>
);

ClothComponent.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  addCloth: PropTypes.func.isRequired,
  removeCloth: PropTypes.func.isRequired,
  submitCloth: PropTypes.func.isRequired,
};

export default ClothComponent;
