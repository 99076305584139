// return 01 for 1, 12 for 12 and 34 for 134 (edge case)
// => do not use for number that can be greater than 99
export function toTwoDigits(number) {
  if (Number.isNaN(number) || number < 0) return '00';
  if (number !== Math.floor(number)) return String(number);
  return (`0${number}`).slice(-2) || '00';
}

// returns 01 for 1, 12 for 12 and 134 for 134
// returns 32.5 for 32.5
export function toTwoDigitsOrMore(number) {
  if (Number.isNaN(number) || number < 0) return '00';
  if (number >= 100) return number;
  if (number !== Math.floor(number)) return String(number);
  return (`0${number}`).slice(-2) || '00';
}

// returns 1,00 for 1, 12,00 for 12 and 134,00 for 134
// returns 32,50 for 32.5, 28,04 for 28.045615161
export function toTwoDecimalDigits(number) {
  if (Number.isNaN(number) || number < 0) return '00,00';
  const numberIt = number.toFixed(2).replace('.', ',');
  if (number >= 10) return numberIt;
  return (`0${numberIt}`) || '00,00';
}
