import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClothesAlterations from './ClothesAlterations';
import Modal from './Modal';
import { getClothTotal } from '../../../core/services/orderPrice';
import { toTwoDecimalDigits } from '../../../services/formatting';
import { fontSizes, margins, colors, Button, TotalContainer, mobileThresholdPixels } from './StyledComponents';
import { formatCloth } from '../../../pagesComponents/order/orderReducer';

const Container = styled.div`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  width: calc(50vw - 2 * ${margins.xl});
  height: 100vh;
  padding: ${margins.xl} ${margins.xl} 0px ${margins.xl};
  overflow: scroll;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 2 * ${margins.m});
    padding: ${margins.m};
  }
`;

const ClothName = styled.h3`
  font-size: ${fontSizes.l};
  font-weight: 500;
  color: ${colors.navy};
  margin: ${margins.s} 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const LineH = styled.div`
  background-color: ${colors.navy};
  height: 2px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${margins.m} 0px;
`;

const ClothTitle = (cloth, index, isOpened, setChoicesCloth) => (
  <div>
    <ClothName onClick={() => setChoicesCloth(cloth, index, null)}>
      {`Retouches ${cloth.name}`}
      {isOpened ?
        <img src="https://icon.now.sh/chevron/up/12" alt="chevron icon" />
        : <img src="https://icon.now.sh/chevron/down/12" alt="chevron icon" />
      }
    </ClothName>
    <LineH />
  </div>
);

class AlterationsPopup extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { show, closeRequest, clothes, selectedClothesSlug, toggleOrSetAlteration,
      showChoicesClothRef, showChoicesClothIndex, openCategory, openedCategory, setChoicesCloth,
      setClothType, deleteCloth, addLining, removeLining, totalPaid } = this.props;
    const showChoicesCloth = clothes[showChoicesClothIndex];
    return (
      <Modal show={show} closeRequest={closeRequest} justify={'right'} noPadding>
        <Container>
          {clothes.map((cloth, index) => {
            if (cloth.slug !== selectedClothesSlug) return null;
            const key = index;
            const isOpened = showChoicesClothIndex === index;
            return (
              <div key={key}>
                {ClothTitle(cloth, index, isOpened, setChoicesCloth)}
                {isOpened &&
                  <ClothesAlterations
                    toggleOrSetAlteration={toggleOrSetAlteration}
                    showChoicesClothRef={showChoicesClothRef}
                    showChoicesCloth={showChoicesCloth}
                    showChoicesClothIndex={showChoicesClothIndex}
                    total={getClothTotal(formatCloth(showChoicesCloth))}
                    openedCategory={openedCategory}
                    openCategory={openCategory}
                    setClothType={setClothType}
                    deleteCloth={deleteCloth}
                    addLining={addLining}
                    removeLining={removeLining}
                  />
                }
              </div>
            );
          })}
          <ButtonContainer>
            <Button onClick={closeRequest}>Valider mes retouches</Button>
          </ButtonContainer>
          <TotalContainer>
            <span>total provisoire des retouches</span>
            <span>{`${toTwoDecimalDigits(totalPaid)}€`}</span>
          </TotalContainer>
        </Container>
      </Modal>
    );
  }
}

AlterationsPopup.propTypes = {
  show: PropTypes.bool,
  closeRequest: PropTypes.func.isRequired,
  clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedClothesSlug: PropTypes.string.isRequired,
  toggleOrSetAlteration: PropTypes.func.isRequired,
  showChoicesClothRef: PropTypes.shape({}),
  showChoicesClothIndex: PropTypes.number,
  openCategory: PropTypes.func.isRequired,
  openedCategory: PropTypes.string,
  setClothType: PropTypes.func.isRequired,
  deleteCloth: PropTypes.func.isRequired,
  setChoicesCloth: PropTypes.func.isRequired,
  addLining: PropTypes.func.isRequired,
  removeLining: PropTypes.func.isRequired,
  totalPaid: PropTypes.number.isRequired,
};

AlterationsPopup.defaultProps = {
  show: true,
  showChoicesClothRef: undefined,
  showChoicesClothIndex: undefined,
  openedCategory: undefined,
};

export default AlterationsPopup;
