import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from './Modal';
import LoginForm from './LoginForm';
import Dots from './Dots';
import { margins, Title2, Subtitle2 } from './StyledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(650px - 2*${margins.m});
  margin-top: ${margins.m};
  padding: 0px ${margins.m};
`;

const FormContainer = styled.div`width: 80%;`;

class LoginPopup extends React.Component {
  constructor(props) {
    super(props);
    const { email, password } = {};
    const customerState = { email, password };
    this.state = { customerState, error: false };
  }

  onChange(event) {
    const field = event.target.name;
    const value = event.target.value;
    this.setState({ [field]: value });
  }

  isFieldValid(field) {
    const fillRequire = !this.state.customerState[field] && this.state.error;
    switch (field) {
      case 'email':
      case 'password':
        return !fillRequire;
      default:
        return false;
    }
  }

  isValid() {
    const customerState = this.state.customerState;
    const emptyFields = Object.keys(customerState).filter(field => !customerState[field]);
    return emptyFields.length === 0;
  }

  submit() {
    if (this.isValid()) {
      this.props.submit(this.state);
    } else {
      this.setState({ error: true });
    }
  }

  render() {
    const { show, submit, closeRequest } = this.props;
    return (
      <Modal show={show} closeRequest={closeRequest}>
        <Container>
          <Title2>se connecter</Title2>
          <Dots />
          <Subtitle2>Connectez-vous<br />avec votre compte Tilli</Subtitle2>
          <FormContainer>
            <LoginForm submit={submit} />
          </FormContainer>
        </Container>
      </Modal>
    );
  }
}

LoginPopup.propTypes = {
  show: PropTypes.bool,
  submit: PropTypes.func,
  closeRequest: PropTypes.func,
};

LoginPopup.defaultProps = {
  show: false,
  submit() {},
  closeRequest() {},
};

export default LoginPopup;
