import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margins } from '../../../../components/Home/v2/StyledComponents';
import Card from './CreditCardRow';
import CreditCard from './CreditCard';

const Container = styled.div`
  margin-top: ${margins.s};
`;

function formatCard(card) {
  const cardNumber = `XXXX XXXX XXXX ${card.alias.slice(12, 16)}`;
  const expirationDate = `${card.expirationDate.slice(0, 2)} / ${card.expirationDate.slice(2, 4)}`;
  return `${cardNumber} - ${expirationDate}`;
}

class CreditCardForm extends React.Component {
  constructor() {
    super();
    this.state = { showMenu: false };
  }

  showAddCard() {
    this.setState({ showAddCard: true, showMenu: false });
    this.props.onChange('selectedCardId', null);
  }

  changeCardId(cardId) {
    this.props.onChange('selectedCardId', cardId);
    this.setState({ showMenu: false, showAddCard: false });
  }

  renderCreditCardsList() {
    const { customer, formValues: { selectedCardId } } = this.props;
    const selectedCard = customer.cards.find(c => c.id === selectedCardId);
    return (
      <Container>
        <Card isFirst isLast={!this.state.showMenu} onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
          {selectedCard ? formatCard(selectedCard) : 'ajouter une carte'}
          {this.state.showMenu ?
            <img src="https://icon.now.sh/chevron/up/12" alt="chevron icon" />
            : <img src="https://icon.now.sh/chevron/down/12" alt="chevron icon" />
          }
        </Card>
        {this.state.showMenu && customer.cards
          .filter(card => card.id !== selectedCardId)
          .map((card, index) =>
            <Card
              key={card.id}
              onClick={() => this.changeCardId(card.id)}
              isLast={(index === customer.cards.length - 1) && !selectedCard}
            >
              {formatCard(card)}
            </Card>)}
        {this.state.showMenu && selectedCard &&
          <Card isLast onClick={() => this.showAddCard()}>ajouter un carte</Card>
        }
      </Container>
    );
  }

  render() {
    const { inputs, customer, formValues, onChange, fillRequest } = this.props;
    return (
      <div>
        {customer && customer.cards && customer.cards.length > 0 && this.renderCreditCardsList()}
        {(this.state.showAddCard || !customer || !customer.cards || customer.cards.length === 0) &&
          <CreditCard
            onChange={onChange}
            formError={fillRequest}
            cardNumber={formValues[inputs.cardNumber.name]}
            expiryDate={formValues[inputs.expiryDate.name]}
            cvc={formValues[inputs.cvc.name]}
            error={''}
          />
        }
      </div>
    );
  }
}

CreditCardForm.propTypes = {
  inputs: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}),
  customer: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }),
  onChange: PropTypes.func,
  fillRequest: PropTypes.bool,
};

CreditCardForm.defaultProps = {
  formValues: {},
  customer: null,
  onChange() {},
  fillRequest: false,
};

export default CreditCardForm;
