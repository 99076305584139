import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { trackEvent } from '../../../services/analytics';
import { margins, Button, FormInput } from '../../../components/Home/v2/StyledComponents';

const ButtonContainer = styled.div`
  text-align: center;
  margin: ${margins.l} 0px;
`;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    const { email, password } = {};
    const customerState = { email, password };
    this.state = { customerState, error: false };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(event) {
    const customerState = this.state.customerState;
    customerState[event.target.name] = event.target.value;
    this.setState({ customerState });
  }

  isFieldValid(field) {
    const fillRequire = !this.state.customerState[field] && this.state.error;
    switch (field) {
      case 'email':
      case 'password':
        return !fillRequire;
      default:
        return false;
    }
  }

  isValid() {
    const customerState = this.state.customerState;
    const emptyFields = Object.keys(customerState).filter(field => !customerState[field]);
    return emptyFields.length === 0;
  }

  submit() {
    if (this.isValid()) {
      trackEvent('click', 'se-connecter_form2');
      this.props.submit(this.state.customerState);
    } else {
      this.setState({ error: true });
    }
  }

  render() {
    return (
      <div>
        <FormInput
          name={'email'} value={this.state.email} full
          placeholder={'Email'}
          onChange={event => this.onChange(event)}
          error={!this.isFieldValid('email')}
        />
        <FormInput
          name={'password'} value={this.state.password} full
          type={'password'}
          placeholder={'Mot de passe'}
          onChange={event => this.onChange(event)}
          error={!this.isFieldValid('password')}
        />
        <ButtonContainer>
          <Button navy onClick={() => this.submit()}>
            Valider
          </Button>
        </ButtonContainer>
      </div>
    );
  }
}

LoginForm.propTypes = {
  submit: PropTypes.func,
};

LoginForm.defaultProps = {
  submit() {},
};

export default LoginForm;
