import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AlterationsPopup from '../AlterationsPopup';
import OrderReducer from '../../../../pagesComponents/order/orderReducer';
import { trackEvent } from '../../../../services/analytics';
import pricing from '../../../../services/pricing';
import { reindexClothesName } from '../../../../core/services/order';

class AlterationsContainer extends Component {
  constructor() {
    super();
    const hasError = () => {};
    this.state = { clothes: [], showChoicesClothRef: {}, showChoicesClothIndex: 0, openedCategory: '', hasError };
    this.openCategory = this.openCategory.bind(this);
    this.setChoicesCloth = this.setChoicesCloth.bind(this);
    this.setClothType = this.setClothType.bind(this);
    this.addLining = this.addLining.bind(this);
    this.removeLining = this.removeLining.bind(this);
    this.deleteCloth = this.deleteCloth.bind(this);
    this.toggleOrSetAlteration = this.toggleOrSetAlteration.bind(this);
  }

  componentDidMount() {
    this.setFirstClothChoices();
    this.changeState(this.props);
  }

  setFirstClothChoices() {
    const { clothes } = this.props;
    if (clothes.length === 0) return;
    const slug = this.props.selectedClothesSlug;
    const index = clothes.findIndex(cloth => cloth.slug === slug);
    const clothRef = pricing.find(clothIt => clothIt.slug === slug);
    this.setState({
      showChoicesClothRef: clothRef,
      showChoicesClothIndex: index,
      openedCategory: clothRef.categories[0].name,
    });
  }

  setChoicesCloth(cloth, index, openedCategory) {
    const clothRef = pricing.find(clothIt => clothIt.slug === cloth.slug);
    if (index === this.state.showChoicesClothIndex) {
      this.setState({
        showChoicesClothRef: null,
        showChoicesClothIndex: null,
        openedCategory: null,
      });
    } else {
      const clothes = this.state.clothes;
      clothes[index].clothType = (clothRef.types && clothRef.types[0] && clothRef.types[0].slug) || '';
      this.setState({
        showChoicesClothRef: clothRef,
        showChoicesClothIndex: index,
        openedCategory: openedCategory || clothRef.categories[0].name,
      });
    }
  }

  setClothType(clothIndex, clothType) {
    trackEvent('changer_cloth_type', clothType);
    const clothes = OrderReducer(
      this.state,
      { type: 'ORDER.SET_CLOTH_TYPE', clothIndex, clothType },
    ).clothes;
    this.setState({ clothes });
  }

  changeState(props) {
    if (props.clothes.length === 0) return;
    this.setState({ clothes: reindexClothesName(props.clothes) });
  }

  addLining(clothIndex) {
    const { clothes } = OrderReducer(this.state, { type: 'ORDER.ADD_LINING', clothIndex });

    // TODO remove duplicate data: either store here or store in parent compoent
    this.props.submitAlterations(clothes);
    this.setState({ clothes });
  }

  removeLining(clothIndex) {
    const { clothes } = OrderReducer(this.state, { type: 'ORDER.REMOVE_LINING', clothIndex });

    // TODO remove duplicate data: either store here or store in parent compoent
    this.props.submitAlterations(clothes);
    this.setState({ clothes });
  }

  openCategory(openedCategory) {
    trackEvent('ouvrir-categorie', openedCategory);
    this.setState({ openedCategory: openedCategory === this.state.openedCategory ? null : openedCategory });
  }

  toggleOrSetAlteration(clothIndex, alterationId, quantity) {
    trackEvent('toggle-retouche', alterationId);
    let clothes = null;
    if (typeof quantity === 'number') {
      clothes = OrderReducer(
        this.state,
        { type: 'ORDER.SET_ALTERATION_QUANITY', clothIndex, alterationId, quantity },
      ).clothes;
    } else {
      clothes = OrderReducer(
        this.state,
        { type: 'ORDER.TOGGLE_ALTERATION', clothIndex, alterationId },
      ).clothes;
    }
    this.props.submitAlterations(clothes);
    this.setState({ clothes });
  }

  deleteCloth(clothIndex, slug) {
    trackEvent('enlever-panier-etape2', slug);
    const clothes = OrderReducer(this.state, { type: 'ORDER.DELETE_CLOTH', clothIndex }).clothes;
    this.props.submitAlterations(clothes);
    this.setState({ clothes });
  }

  isOrderValid() {
    return this.state.clothes && this.state.clothes.length > 0
      && !this.state.clothes.find(cloth => !cloth.alterations || cloth.alterations.length === 0);
  }

  render() {
    const isButtonValid = this.isOrderValid();
    const { clothes, showChoicesClothRef, showChoicesClothIndex } = this.state;
    return (
      <div>
        {clothes.length !== 0 &&
          <AlterationsPopup
            closeRequest={this.props.closeRequest}
            clothes={clothes}
            selectedClothesSlug={this.props.selectedClothesSlug}
            toggleOrSetAlteration={this.toggleOrSetAlteration}
            showChoicesClothRef={showChoicesClothRef}
            showChoicesCloth={clothes[showChoicesClothIndex]}
            showChoicesClothIndex={showChoicesClothIndex}
            openedCategory={this.state.openedCategory}
            openCategory={this.openCategory}
            setClothType={this.setClothType}
            setChoicesCloth={this.setChoicesCloth}
            isButtonValid={isButtonValid}
            hasError={this.state.hasError}
            deleteCloth={this.deleteCloth}
            addLining={this.addLining}
            removeLining={this.removeLining}
            totalPaid={this.props.totalPaid}
          />
        }
      </div>
    );
  }
}

AlterationsContainer.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeRequest: PropTypes.func.isRequired,
  submitAlterations: PropTypes.func.isRequired,
  totalPaid: PropTypes.number.isRequired,
  selectedClothesSlug: PropTypes.string.isRequired,
};

export default AlterationsContainer;
