import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';

import PromoCodeField from './order/PromoCodeField';
import { margins, colors, Subtitle2, LineH } from '../../../components/Home/v2/StyledComponents';
import momentFr from '../../../tilli-components/src/config/moment.fr';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');


const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: Roboto;
  ${props => !props.noMargin && `margin: ${margins.s} 0px`}
`;

const Column = styled.div`
  display: flex;
  flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${margins.m};
  padding: ${props => props.noPadding ? '0px' : margins.m};
  background-color: ${colors.white};
`;

const StyledSubTitle2 = styled(Subtitle2)`
  text-align: left;
  margin-top: ${margins.l};
`;

const A = styled.a`
  text-align: center;
  margin-top: 5px;
  font-size: inherit;
  text-decoration: underline;
`;

const TextContainer = styled.div`
  font-family: Roboto;
  font-size: 12px;
  ${props => props.bold && `
    letter-spacing: 1px;
    font-weight: bold;
  `}
`;

const Span2 = styled.span`
  font-family: Roboto;
  font-size: 10px;
`;

const SummaryRow = (texts, { showDown = false, showUp = true } = {}) => (
  <Column>
    {showUp && <LineH style={{ margin: `${margins.s} 0px` }} />}
    <Row noMargin>
      <TextContainer>{texts[0]}</TextContainer>
      <TextContainer>{texts[1]}</TextContainer>
    </Row>
    {showDown && <LineH style={{ margin: `${margins.s} 0px` }} />}
  </Column>
);

function formatSlots(slots) {
  if (slots.length === 0) return null;
  else if (slots.length === 1) {
    return `${moment(slots[0].begin).format('dddd D MMMM [de] HH.mm')} à
      ${moment(slots[0].end).format('HH.mm')}`;
  }
  return `${moment(slots[0].begin).format('dddd D MMMM [de] HH.mm')} à
    ${moment(slots[0].end).format('HH.mm')} et d'autres créneaux`;
}

const OrderSummary = ({ deliveryFee, minOrderAmount, minOrderAmountFee, promoCode,
  errorPromoCode, total, submit, slots, goToEstimate, totalClothes, noPadding, hidePromoCode,
}) => (
  <div>
    <StyledSubTitle2>Récapitulatif de votre commande</StyledSubTitle2>
    <SubContainer noPadding={noPadding}>
      <ReactTooltip />
      <TextContainer bold>Votre rendez-vous du {formatSlots(slots)}</TextContainer>
      {SummaryRow(['total des retouches',
        (totalClothes > 0) ?
          `${totalClothes}€` :
          <A onClick={goToEstimate} data-tip="Votre Tilliste fera l'esimation lors du RDV">estimé en rendez-vous</A>])}
      {(minOrderAmountFee > 0) && SummaryRow(['minimum de commande', `${minOrderAmount}€`])}
      {SummaryRow(['frais de déplacement', `${deliveryFee}€`])}
      {!hidePromoCode &&
        <Row>
          <PromoCodeField promoCode={promoCode} errorPromoCode={errorPromoCode} submit={submit} />
        </Row>
      }
      {SummaryRow([
        <span>montant total<br /> <Span2>taxes incluses</Span2></span>,
        `${total}€`,
      ], { showDown: true, showUp: hidePromoCode })}
    </SubContainer>
  </div>
);

OrderSummary.propTypes = {
  deliveryFee: PropTypes.number.isRequired,
  minOrderAmount: PropTypes.number.isRequired,
  minOrderAmountFee: PropTypes.number.isRequired,
  promoCode: PropTypes.shape({}),
  errorPromoCode: PropTypes.bool,
  noPadding: PropTypes.bool,
  hidePromoCode: PropTypes.bool,
  total: PropTypes.number.isRequired,
  totalClothes: PropTypes.number.isRequired,
  submit: PropTypes.func,
  goToEstimate: PropTypes.func,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
};

OrderSummary.defaultProps = {
  promoCode: {},
  errorPromoCode: false,
  noPadding: false,
  hidePromoCode: false,
  submit() {},
  goToEstimate() {},
  slots: [],
};

export default OrderSummary;
