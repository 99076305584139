import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ViewPager, Frame, Track, View } from 'react-view-pager';

import withAppContext from '../../../withAppContext';
import Slider from './Slider';
import Slot from '../../../tilli-components/src/components/Slot';
import { margins, mobileThresholdPixels } from '../../../tilli-components/src/components/styledComponents';

import arrowLeft from '../../../tilli-components/src/assets/arrow.left.navy.svg';
import arrowRight from '../../../tilli-components/src/assets/arrow.right.navy.svg';

const frameStyle = {
  width: '100%',
  position: 'relative',
  outline: 'none',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const ControlContainer = styled.div``;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 30px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
    width: 15px;
  }
`;

function createSlot(hours, is30 = false) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}.30` : `${hours}.00`,
    string2: is30 ? `${hours + 1}.00` : `${hours}.30`,
  };
}

function createSlots(beginHour, endHour, removeLastOne = true) {
  const slots = [];
  let slotHour;
  for (slotHour = beginHour; slotHour <= endHour; slotHour += 1) {
    slots.push(createSlot(slotHour));
    slots.push(createSlot(slotHour, true));
  }
  if (removeLastOne) slots.pop();
  return slots;
}

const isSelected = (slots, slot) =>
  slots.reduce((selected, slotIt) =>
    selected || (slotIt.hours === slot.hours && slotIt.minutes === slot.minutes),
  false);

// use class component for this refs
class SlotSelector extends React.Component {
  render() {
    const { beginHour, selectedSlots, onChange, context } = this.props;
    const activeSlotsRange = createSlots(beginHour, 21);
    const { isMobile } = context;
    return (
      <ViewPager tag="main" style={viewPagerStyle}>
        <ControlContainer>
          <ControlImg src={arrowLeft} onClick={() => this.track.prev()} />
        </ControlContainer>
        {!isMobile ?
          <Frame className="frame" style={frameStyle}>
            <Track
              ref={(c) => { this.track = c; }}
              viewsToShow={Math.min(9, activeSlotsRange.length)}
              viewsToMove={Math.min(9, activeSlotsRange.length)}
              className="track"
            >
              {activeSlotsRange.map(slot =>
                <View className="view" key={slot.string1}>
                  <Slot
                    isSelected={isSelected(selectedSlots, slot)}
                    onClick={() => onChange(slot.hours, slot.minutes)}
                    slot={slot}
                  />
                </View>)}
            </Track>
          </Frame> :
          <Slider
            ref={(c) => { this.track = c; }}
            viewsToShow={Math.min(4, activeSlotsRange.length)}
            viewsToMove={Math.min(2, activeSlotsRange.length)}
          >
            {activeSlotsRange.map(slot =>
              <Slot
                isSelected={isSelected(selectedSlots, slot)}
                onClick={() => onChange(slot.hours, slot.minutes)}
                slot={slot}
                key={slot.string1}
              />,
            )}
          </Slider>
        }
        <ControlContainer right>
          <ControlImg src={arrowRight} onClick={() => this.track.next()} />
        </ControlContainer>
      </ViewPager>
    );
  }
}

SlotSelector.propTypes = {
  onChange: PropTypes.func,
  beginHour: PropTypes.number,
  selectedSlots: PropTypes.arrayOf(PropTypes.shape({})),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

SlotSelector.defaultProps = {
  onChange() {},
  beginHour: 9,
  selectedSlots: [],
};

export default withAppContext(SlotSelector);
