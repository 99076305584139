import React from 'react';
import PropTypes from 'prop-types';
import CardReactFormContainer from 'card-react';
import { Form1, Row, ErrorText } from '../../../styledComponents';
import { FormInput } from '../StyledComponents';

const CreditCard = ({ onChange, formError, cardNumber, expiryDate, cvc, error }) => (
  <CardReactFormContainer
    container="card-wrapper"
    formInputsNames={{
      number: 'CCnumber',
      expiry: 'CCexpiry',
      cvc: 'CCcvc',
      name: 'CCname',
    }}
    initialValues={{
      name: ' ',
      number: cardNumber,
      expiry: expiryDate,
      cvc,
    }}
    classes={{
      valid: 'valid-input', // optional — default 'jp-card-valid'
      invalid: 'invalid-input', // optional — default 'jp-card-invalid'
    }}
    formatting // specify whether you want to format the form inputs or not
  >

    <div id="card-wrapper" />
    <Form1 marginTop="0px">
      <FormInput
        full
        type="text" name="CCnumber"
        placeholder="Numéro de carte"
        onChange={e => onChange('cardNumber', e.target.value)}
        error={formError && !cardNumber}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <Row>
        <FormInput
          placeholder="MM / AA (date d‘expiration)" type="text" name="CCexpiry"
          onChange={e => onChange('expiryDate', e.target.value)}
          error={formError && !expiryDate}
        />
        <FormInput
          placeholder="CVC (3 chiffres au dos)" type="text" name="CCcvc"
          onChange={e => onChange('cvc', e.target.value)}
          error={formError && !cvc}
        />
      </Row>
    </Form1>

  </CardReactFormContainer>
);

CreditCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  formError: PropTypes.bool,
  cardNumber: PropTypes.string,
  expiryDate: PropTypes.string,
  cvc: PropTypes.string,
  error: PropTypes.string,
};

CreditCard.defaultProps = {
  formError: false,
  cardNumber: '',
  expiryDate: '',
  cvc: '',
  error: '',
};

export default CreditCard;
