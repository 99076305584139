import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isMobile from '../services/isMobile';
import { trackEvent } from '../services/analytics';
import Dots from '../components/Home/v2/Dots';
import FabricsSelector from '../components/Home/v2/FabricsSelector';
import { Column } from '../tilli-components/src/components/styledComponents';
import { margins, Title2, Subtitle2, Button, Content, OrderStepsA }
  from '../components/Home/v2/StyledComponents';

const ButtonContainer = styled.div`
  padding: ${margins.s};
`;

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({ selectedFabrics: [] });
  }

  onChange(slug) {
    let selectedFabrics = [...this.state.selectedFabrics];
    const slugIndex = selectedFabrics.findIndex(fabricIt => fabricIt === slug);
    if (slugIndex >= 0) {
      selectedFabrics.splice(slugIndex, 1);
    } else {
      selectedFabrics = [...selectedFabrics, slug];
    }
    this.setState({ selectedFabrics });
    this.props.onChange(selectedFabrics);
  }

  goNext() {
    trackEvent('click', 'suivant_block-step2');
    this.props.goNext();
  }

  goToEstimate() {
    trackEvent('click', 'jeVeuxEstimer_block-step2');
    this.props.goToEstimate();
  }

  render() {
    return (
      <Content lightGrey>
        <Column justifyContent={'center'} alignItems={'center'}>
          <Title2>Matières</Title2>
          <Dots />
          <Subtitle2 muchText>
            {!isMobile() ?
              <span>
                Listez les matières concernées par vos retouches,<br />
                nous vous envoyons un spécialiste.
              </span> :
              <span>
                Listez les matières concernées<br />par vos retouches,
                nous vous<br />envoyons un spécialiste.
              </span>
            }
          </Subtitle2>
        </Column>
        <Column justifyContent={'center'} alignItems={'center'}>
          <FabricsSelector
            selectedFabrics={this.state.selectedFabrics}
            toggle={slug => this.onChange(slug)}
          />
          <ButtonContainer>
            <Button navy onClick={() => this.goNext()}>
              Indiquer mon adresse
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <Button transparent onClick={() => this.goToEstimate()}>
              Estimer ma commande
            </Button>
          </ButtonContainer>
        </Column>
      </Content>
    );
  }
}

Step2.propTypes = {
  goNext: PropTypes.func,
  goToEstimate: PropTypes.func,
  onChange: PropTypes.func,
};

Step2.defaultProps = {
  goNext() {},
  goToEstimate() {},
  onChange() {},
};

export default Step2;
