import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import pricing from '../services/pricing';
import { trackEvent } from '../services/analytics';
import ClothesSelector from '../tilli-components/src/components/ClothesSelector';
import AlterationsContainer from '../components/Home/v2/order/AlterationsContainer';
import Dots from '../components/Home/v2/Dots';
import { margins } from '../tilli-components/src/components/styledComponents';
import { Button, Title2, Subtitle2, Content, OrderStepsA } from '../components/Home/v2/StyledComponents';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  padding: ${margins.s} 0px;
`;

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedClothes: [] };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ selectedClothes: newProps.clothes });
  }

  addCloth(slug) {
    const clothRef = pricing.find(clothIt => clothIt.slug === slug);
    const cloth = {
      slug,
      name: clothRef.name.toLowerCase(),
      liningsNumber: 0,
      clothType: clothRef.types && clothRef.types[0] && clothRef.types[0].slug,
    };
    this.setState({ selectedClothes: [...this.state.selectedClothes, cloth] });
  }

  removeCloth(slug) {
    const slugIndex = this.state.selectedClothes.findIndex(cloth => cloth.slug === slug);
    const selectedClothes = this.state.selectedClothes;
    selectedClothes.splice(slugIndex, 1);
    this.setState({ selectedClothes });
  }

  submitCloth(clothSlug) {
    this.setState({ showAlterations: clothSlug });
  }

  submit() {
    trackEvent('click', 'suivant_block-step3');
    this.props.goNext();
    this.setState({ showAlterations: null });
  }

  submitAlterations(clothes) {
    this.props.onChange(clothes);
    this.setState({ selectedClothes: clothes });
  }

  render() {
    return (
      <Content>
        <Column>
          <Title2>Retouches</Title2>
          <Dots />
          <Subtitle2>Choisissez vos vêtements<br />et retouches</Subtitle2>
        </Column>
        <Column>
          <ClothesSelector
            pricings={pricing}
            clothes={this.state.selectedClothes}
            addCloth={slug => this.addCloth(slug)}
            removeCloth={slug => this.removeCloth(slug)}
            submitCloth={slug => this.submitCloth(slug)}
          />
          <ButtonContainer>
            <Button navy onClick={() => this.submit()}>
              Indiquer mon adresse
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <Button
              onClick={() => {
                trackEvent('click', 'jeVeuxEstimer_block-step3'); this.props.goNext();
              }}
            >
              passer cette étape
            </Button>
          </ButtonContainer>
        </Column>
        {this.state.showAlterations &&
          <AlterationsContainer
            clothes={this.state.selectedClothes}
            selectedClothesSlug={this.state.showAlterations}
            totalPaid={this.props.totalPaid}
            closeRequest={request => this.setState({ showAlterations: !request })}
            submitAlterations={clothes => this.submitAlterations(clothes)}
          />
        }
      </Content>
    );
  }
}

Step3.propTypes = {
  goNext: PropTypes.func,
  onChange: PropTypes.func,
  totalPaid: PropTypes.number,
};

Step3.defaultProps = {
  goNext() {},
  onChange() {},
  totalPaid: 0,
};

export default Step3;
