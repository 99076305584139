import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels, fontSizes } from './styledComponents';

const SlotContainer = styled.div`
  box-sizing: border-box;
  flex-basis: auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  font-family: Gotham;
  text-align:center;

  width: 80px;
  height: 80px;
  margin: 5px 10px;
  padding-top: 3px;
  border: 1px solid ${colors.navy};

  ${props => props.isSelected ? `color: ${colors.white}` : `color: ${colors.navy}`};
  ${props => props.isSelected ? `background: ${colors.navy}` : ''};
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    width: 50px;
    height: 50px;
    font-size: ${fontSizes.s};
    padding: 10px 0px;
    margin: 5px;
  }
`;

const Slot = ({ slot, isSelected, onClick }) => (
  <SlotContainer
    isSelected={isSelected}
    key={slot.string1}
    onClick={onClick}
  >
    {slot.string1}<br />
    {slot.string2}
  </SlotContainer>
);

Slot.propTypes = {
  slot: PropTypes.shape({}).isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

Slot.defaultProps = {
  isSelected: false,
  onClick() {},
};

export default Slot;
