import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toTwoDigits, toTwoDecimalDigits } from '../../../services/formatting';
import featureSwitch from '../../../services/featureSwitch';
import Alterations from './Alterations';
import { colors, margins, ClothType, TotalContainer } from './StyledComponents';

const AddLining = styled.span`
  cursor: pointer;
  padding: ${props => props.add ? '20px 0px 20px 10px' : '20px 10px 20px 0px'};
`;

const ClothTypes = styled.div`
  display: flex;
  width: 100%;
  margin: ${margins.m} 0px 0px 0px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${margins.m};
  padding-right: 5px;
  color: ${colors.navy};
  font-size: 1em;
  font-weight: 500;
`;

const ClothesAlterations = ({ showChoicesCloth, showChoicesClothRef, total, openCategory, openedCategory,
  showChoicesClothIndex, toggleOrSetAlteration, setClothType, addLining, removeLining }) =>
  (
    showChoicesCloth && showChoicesClothRef ?
      <div>
        {showChoicesClothRef.types && (showChoicesClothRef.types.length > 0) &&
          (<ClothTypes>
            {showChoicesClothRef.types.map(type => (
              <ClothType
                key={type.slug}
                onClick={() => setClothType(showChoicesClothIndex, type.slug)}
                isSelected={showChoicesCloth.clothType === type.slug}
              >{showChoicesClothRef.name.toLowerCase()} {type.name}</ClothType>
            ))}
          </ClothTypes>)
        }

        {!featureSwitch('step2_noLining') &&
          <Row>
            <span style={{ color: colors.navy }}>Nombre de doublures :</span>
            <span style={{ color: colors.navy }}>
              <AddLining onClick={() => removeLining(showChoicesClothIndex)}>{'- '}</AddLining>
              <span>{toTwoDigits(showChoicesCloth.liningsNumber || 0)}</span>
              <AddLining onClick={() => addLining(showChoicesClothIndex)} add>{' +'}</AddLining>
            </span>
          </Row>
        }
        <Alterations
          clothRef={showChoicesClothRef}
          openedCategory={openedCategory}
          openCategory={openCategory}
          cloth={showChoicesCloth}
          toggleOrSetAlteration={(alterationId, quantity) =>
            toggleOrSetAlteration(showChoicesClothIndex, alterationId, quantity)}
        />
        <TotalContainer>
          <span>total {showChoicesCloth.name}</span>
          <span>{`${toTwoDecimalDigits(total)}€`}</span>
        </TotalContainer>
      </div> : <div />
  );

ClothesAlterations.propTypes = {
  toggleOrSetAlteration: PropTypes.func.isRequired,
  showChoicesCloth: PropTypes.shape({}),
  showChoicesClothRef: PropTypes.shape({}),
  showChoicesClothIndex: PropTypes.number,
  total: PropTypes.number.isRequired,
  openCategory: PropTypes.func.isRequired,
  openedCategory: PropTypes.string,
  setClothType: PropTypes.func.isRequired,
  addLining: PropTypes.func.isRequired,
  removeLining: PropTypes.func.isRequired,
};

ClothesAlterations.defaultProps = {
  showChoicesCloth: undefined,
  showChoicesClothRef: undefined,
  showChoicesClothIndex: undefined,
  openedCategory: undefined,
};


export default ClothesAlterations;
