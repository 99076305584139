import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { navigate } from 'gatsby';

import routes from '../../Routes';
import Step1 from '../../components/Step1';
import Step2 from '../../components/Step2';
import Step3 from '../../components/Step3';
import Step4 from '../../components/Step4';
import Footer from '../../components/Home/Footer';
import MenuBar from '../../components/Home/MenuBar';
import callApi from '../../services/api';
import { getDeliveryFees } from '../../services/zipcode';
import { trackEvent, trackUser } from '../../services/analytics';
import { metadata } from '../../services/pricing';
import { formatCloth } from './orderReducer';
import { getTotalPaid, getMinOrderAmountFee, getTotalClothes } from '../../core/services/orderPrice';
import { mobileThresholdPixels, colors, margins, Container }
  from '../../components/Home/v2/StyledComponents';
import { registerCard, errorMessages } from '../../services/mangopay';
import Layout from '../../layouts/index';

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: ${margins.xl} 0px;
  ${props => props.lightGrey && `background-color: ${colors.lightGrey}`};

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - (2 * ${margins.s}));
    padding: ${margins.l} ${margins.s};
  }
`;

function trackOrderForFacebook(total) {
  if (typeof fbq === 'function') {
    // eslint-disable-next-line no-undef
    fbq('track', 'Purchase', { value: total, currency: 'EUR' });
  } else if (typeof Raven !== 'undefined') {
    Raven.captureException('fbq tracking is undefined'); // eslint-disable-line
  } else {
    console.error('fbq tracking is undefined');
  }
}

function getErrorType(response) {
  if (response.errors && response.errors.email) {
    return 'emailUnique';
  }
  return response.error;
}

function getErrorMessage(error) {
  if (errorMessages[error]) return errorMessages[error];
  return 'Une erreur a eu lieu';
}

const scrollToStep = (ref) => {
  ref.scrollIntoView({ behavior: 'smooth' });
};

function createCustomer(customerData) {
  const { firstname, lastname, password, email, phone, street, zipcode, locality, comment, deliveryFee }
    = customerData;
  const address = { street, zipcode, locality, comment };
  const customer = { firstname, lastname, password, email, phone, address, deliveryFee };
  // const card = { expiryDate, cvc, cardNumber, cardId };
  return customer;
}

function redirectToSuccess(total, { showCard = false, order } = {}) {
  trackEvent('order', 'order', 'total', total);
  trackOrderForFacebook(total);
  return navigate(routes.Success.url, { state: { showCard, order } });
}

class OrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { slots: [], fabrics: [], clothes: [], deliveryFee: 5 };
    this.goToStep2 = this.goToStep2.bind(this);
    this.goToStep3 = this.goToStep3.bind(this);
    this.goToStep4 = this.goToStep4.bind(this);
    this.updateDeliveryFee = this.updateDeliveryFee.bind(this);
  }

  onChange(stepName, stepState) {
    trackEvent(`onChange_${stepName}`);
    this.setState({ [stepName]: stepState });
  }

  changeSlots(slotsDays) {
    const formattedSlots = [];
    let { slots, begin, end } = {};
    Object.keys(slotsDays).forEach((day) => {
      slots = slotsDays[day];
      slots.forEach((slot) => {
        begin = moment(day).set({ hour: slot.hours, minute: slot.minutes });
        end = moment(begin).add(30, 'minutes');
        formattedSlots.push({ begin: begin.toDate(), end: end.toDate() });
      });
    });
    if (formattedSlots.length > 0) {
      trackEvent('onChange_slots', String(formattedSlots.length));
    }
    this.setState({ slots: formattedSlots });
  }

  createRequest(customer = {}, cardData = {}) {
    const order = {
      rdv1Slots: this.state.slots,
      fabrics: this.state.fabrics,
      minOrderAmount: metadata.minOrderAmount,
      deliveryFee: this.state.deliveryFee || getDeliveryFees(customer && customer.zipcode),
      pricingVersion: metadata.version,
      clothes: this.state.clothes.map(cloth => formatCloth(cloth)),
      promoCode: this.state.promoCode && this.state.promoCode.code,
      discounts: (this.state.promoCode && [this.state.promoCode]) || [],
      cardToken: cardData.cardId,
    };
    return { customer, order };
  }

  updateDeliveryFee(zipcode) {
    const deliveryFee = getDeliveryFees(zipcode);
    if (typeof deliveryFee === 'number') {
      this.setState({ deliveryFee });
    }
  }

  login({ email, password }) {
    callApi('login', 'post', { email, password })
      .then(({ customer }) => {
        trackUser(customer);
        trackEvent('login');
        this.setState({ customer, error: false, errorType: null });
      })
      .catch(() => {
        trackEvent('erreur', 'echec-login');
      });
  }

  loginWithFacebook({ accessToken }) {
    this.setState({ isLoginLoading: true });
    callApi('login', 'POST', { fbAccessToken: accessToken }).then(({ customer }) => {
      this.setState({ isLoginLoading: false, error: false, errorType: null });
      trackUser(customer);
      trackEvent('login-fb');
      this.setState({ customer });
    }).catch(() => {
      trackEvent('erreur', 'echec-login-fb');
    });
  }

  checkPromoCode(promoCode) {
    callApi(`public/checkPromoCode/${promoCode}`)
      .then((res) => {
        trackEvent('ajout-code-promo', promoCode);
        this.setState({ promoCode: res.promoCode, errorPromoCode: false });
      })
      .catch(() => {
        trackEvent('ajout-code-promo-echec', promoCode);
        this.setState({ promoCode: null, errorPromoCode: true });
      });
  }

  submitOrder(formData, cardId) {
    const { cardNumber, expiryDate, cvc } = formData;
    if (this.state.customer && this.state.customer.firstname) {
      this.submitLoggedInOrder(createCustomer(formData), { cardNumber, expiryDate, cvc, cardId });
    } else {
      this.submitRegisterAndOrder(createCustomer(formData), { cardNumber, expiryDate, cvc });
    }
  }

  submitCard(cardData, order, customer) {
    if (cardData.cardId) {
      return redirectToSuccess(getTotalPaid(order));
    }
    return registerCard(cardData, customer._id) // eslint-disable-line no-underscore-dangle
      .then(card =>
        callApi('public/submitCard', 'post',
          { card, orderId: order._id, brand: process.env.GATSBY_BRAND })) // eslint-disable-line no-underscore-dangle
      .then(() => redirectToSuccess(getTotalPaid(order)))
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        trackEvent('erreur', response.error);
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  submitRegisterAndOrder(customerReq, cardData) {
    this.setState({ isLoading: true });
    callApi('registerAndOrder', 'post', this.createRequest(customerReq, cardData))
      .then(({ order, customer }) => {
        trackUser(customer);
        redirectToSuccess(getTotalPaid(order), { showCard: true, order });
      })
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: getErrorType(response) });
        trackEvent('erreur', 'echec-soumission-commande-non-connecte');
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  submitLoggedInOrder(customerReq, cardData) {
    this.setState({ isLoading: true });
    callApi('editCustomerAndOrder', 'post', this.createRequest(customerReq, cardData))
      .then(({ order, customer }) => {
        if (cardData && (cardData.cardNumber || cardData.cardId)) {
          return this.submitCard(cardData, order, customer);
        }
        return redirectToSuccess(getTotalPaid(order), { showCard: true, order });
      })
      .catch((response) => {
        trackEvent('erreur', 'echec-soumission-commande-connecte');
        this.setState({ error: true, isLoading: false, errorType: getErrorType(response) });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  goToStep2() {
    trackEvent('goToStep2');
    if (typeof fbq === 'function') fbq('track', 'AddToCart'); // eslint-disable-line no-undef
    this.setState({ showStep2: true }, () => scrollToStep(this.step2));
  }
  goToStep3() {
    trackEvent('goToStep3');
    this.setState({ showStep3: true }, () => scrollToStep(this.step3));
  }
  goToStep4() {
    trackEvent('goToStep4');
    this.setState({ showStep4: true }, () => scrollToStep(this.step4));
  }

  render() {
    const { customer, deliveryFee } = this.state;
    const { order } = this.createRequest(customer);
    const totalPaid = getTotalPaid(order);
    const minOrderAmount = metadata.minOrderAmount;
    const minOrderAmountFee = getMinOrderAmountFee(order);
    const totalClothes = getTotalClothes(order);
    const errorOrder = this.state.error ? getErrorMessage(this.state.errorType) : null;
    return (
      <Layout routeSlug="OrderPage">
        <Container>
          <MenuBar />
          <StepContainer>
            <Step1
              goNext={this.goToStep2}
              onChange={slots => this.changeSlots(slots)}
            />
          </StepContainer>
          <div ref={(c) => { this.step2 = c; }} >
            {this.state.showStep2 &&
              <StepContainer lightGrey>
                <Step2
                  goNext={this.goToStep4}
                  goToEstimate={() => this.goToStep3()}
                  onChange={state => this.onChange('fabrics', state)}
                />
              </StepContainer>
            }
          </div>
          <div ref={(c) => { this.step3 = c; }} >
            {this.state.showStep3 &&
              <StepContainer>
                <Step3
                  clothes={order.clothes}
                  totalPaid={totalPaid}
                  goNext={this.goToStep4}
                  onChange={state => this.onChange('clothes', state)}
                />
              </StepContainer>
            }
          </div>
          <div ref={(c) => { this.step4 = c; }} >
            {this.state.showStep4 &&
              <StepContainer lightGrey>
                <Step4
                  slots={this.state.slots}
                  customer={customer}
                  showPayment={customer && customer.cards && (customer.cards.length > 0)}
                  deliveryFee={deliveryFee}
                  minOrderAmount={minOrderAmount}
                  minOrderAmountFee={minOrderAmountFee}
                  promoCode={this.state.promoCode}
                  errorPromoCode={this.state.errorPromoCode}
                  errorOrder={errorOrder}
                  total={totalPaid}
                  totalClothes={totalClothes}
                  submitForm={(formData, cardId) => this.submitOrder(formData, cardId)}
                  submitLogin={({ email, password }) => this.login({ email, password })}
                  submitFacebookLogin={({ accessToken }) => this.loginWithFacebook({ accessToken })}
                  submitPromoCode={promoCode => this.checkPromoCode(promoCode)}
                  goToEstimate={this.goToStep3}
                  isLoading={this.state.isLoading}
                  onZipcodeChange={this.updateDeliveryFee}
                />
              </StepContainer>
            }
          </div>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default OrderPage;
