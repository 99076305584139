import React from 'react';
import PropTypes from 'prop-types';
import { ClothesList } from './styledComponents';
import Cloth from './Cloth';

import pant from '../assets/order/alterations/pants.jpg';
import haut from '../assets/order/alterations/shirt.jpg';
import robe from '../assets/order/alterations/dress.jpg';
import mari from '../assets/order/alterations/wedding.jpg';
import pull from '../assets/order/alterations/pullover.jpg';
import mant from '../assets/order/alterations/coat.jpg';
import jupe from '../assets/order/alterations/skirt.jpg';
import ling from '../assets/order/alterations/shorts.jpg';
import deco from '../assets/order/alterations/curtain.jpg';

const clothesImages = { pant, haut, robe, mari, pull, mant, jupe, ling, deco };

const ClothesSelector = ({ pricings, clothes, addCloth, removeCloth, submitCloth }) => (
  <ClothesList>
    {pricings.map(cloth =>
      <Cloth
        key={cloth.slug}
        name={cloth.name}
        quantity={clothes.reduce((total, cloth2) => cloth2.slug === cloth.slug ? total + 1 : total, 0)}
        image={clothesImages[cloth.slug]}
        addCloth={() => addCloth(cloth.slug)}
        removeCloth={() => removeCloth(cloth.slug)}
        submitCloth={() => submitCloth(cloth.slug)}
      />,
    )}
  </ClothesList>
);

ClothesSelector.propTypes = {
  pricings: PropTypes.arrayOf(PropTypes.shape({})),
  clothes: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
  })),
  addCloth: PropTypes.func,
  removeCloth: PropTypes.func,
  submitCloth: PropTypes.func,
};

ClothesSelector.defaultProps = {
  pricings: [],
  clothes: [],
  addCloth() {},
  removeCloth() {},
  submitCloth() {},
};

export default ClothesSelector;
