import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Price, colors, mobileThresholdPixels,
  smallMobileThresholdPixels } from '../../styledComponents';
import { margins, Categories, Category, Header3 } from './StyledComponents';
import { toTwoDigitsOrMore } from '../../../services/formatting';
import featureSwitch from '../../../services/featureSwitch';
import isIE from '../../../services/isIE';

const Title4 = styled.h4`
  color: ${colors.navy};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.navy};
  margin: 0px;
  padding: ${margins.s} 0px calc(${margins.s} - 2px);
  font-weight: 500;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    padding: 10px;
  }
`;

const AlterationsTextContainer = styled.div`
  flex: 1;
`;

const AddContainer = styled.div`
  ${() => isIE() ? 'width: 80px;' : 'flex: 0;'}
  margin-right: 20px;
  pointer-events: none;
`;

const Add = styled.span`
  pointer-events: auto;
  color: ${colors.navy};
  padding: ${props => props.add ? '10px 0px 10px 10px' : '10px 10px 10px 0px'};
`;

const PriceContainer = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Choice = styled.div`
  cursor: pointer;
  padding: 10px;
  color: ${colors.navy};
  background: ${props => props.isSelected ? colors.lightGrey : 'initial'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  opacity: 0.9;
  transition: all 0.3s ease;
  font-weight: 300;
  border-top: 1px solid ${colors.navy}80;

  ::selection {
    background: transparent;
  }
`;

const Bold = styled.div`
  font-weight: normal;
`;

const Note = styled.div`
  font-size: 0.9em;
`;

function onChoiceClick(quantity, item, toggleOrSetAlteration) {
  if (item.multiple) {
    toggleOrSetAlteration(item.id, quantity + 1);
  } else {
    toggleOrSetAlteration(item.id);
  }
}

function renderItem(item, toggleOrSetAlteration, cloth, mostCommonCategory) {
  const quantity = (cloth.alterations && cloth.alterations[item.id]) || 0;
  const liningsNumber = cloth.liningsNumber || 0;
  const liningUnitPrice = item.liningUnitPrice || 0;
  const liningPrice = liningUnitPrice * liningsNumber;
  const showLiningPrice = !featureSwitch('step2_noLining') && !featureSwitch('alterations_hideSkipOption');
  return (
    <Choice
      isSelected={quantity}
      onClick={() => onChoiceClick(quantity, item, toggleOrSetAlteration)}
      key={item.id}
    >
      <AddContainer>
        {<Add onClick={(e) => { toggleOrSetAlteration(item.id, quantity - 1); e.stopPropagation(); }}>-</Add>}
        <span>{toTwoDigitsOrMore(quantity)}</span>
        {<Add
          onClick={(e) => { toggleOrSetAlteration(item.id, item.multiple ? quantity + 1 : 1); e.stopPropagation(); }}
          add
        >+</Add>}
      </AddContainer>
      <AlterationsTextContainer>
        <Bold>
          {(mostCommonCategory && item.mostCommonCategoryLabel) ?
            item.mostCommonCategoryLabel.toLocaleLowerCase().replace('a.p.c.', 'A.P.C.') :
            item.name.toLocaleLowerCase().replace('a.p.c.', 'A.P.C.')}
        </Bold>
        {showLiningPrice &&
          <Note>prix de la doublure</Note>
        }
      </AlterationsTextContainer>
      <PriceContainer>
        {!featureSwitch('alterations_hideSkipOption') &&
          <Price>{toTwoDigitsOrMore(item.price)}€</Price>
        }
        {showLiningPrice &&
          <Price>+{toTwoDigitsOrMore(liningPrice)}€</Price>
        }
      </PriceContainer>
    </Choice>
  );
}

const Alterations = ({ clothRef, openedCategory, openCategory, toggleOrSetAlteration,
  cloth }) => (
  // eslint-disable-next-line
  <Categories>
    {clothRef.categories.map(category =>
      <div key={category.name}>
        <Category onClick={() => openCategory(category.name)}>
          <Header3>
            <Title4>
              {` ${category.name}`}
              {openedCategory === category.name ?
                <img src="https://icon.now.sh/chevron/up/12" alt="chevron icon" />
                : <img src="https://icon.now.sh/chevron/down/12" alt="chevron icon" />
              }
            </Title4>
          </Header3>
        </Category>
        {openedCategory === category.name &&
          category.items
            .filter(item => item && !item.hidden)
            .filter(item => (clothRef.types.length === 0) || (item.type === cloth.clothType))
            .map(item =>
              renderItem(item, toggleOrSetAlteration, cloth, category.mostCommonCategory),
            )}
      </div>,
    )}
  </Categories>
);

Alterations.propTypes = {
  clothRef: PropTypes.shape({}).isRequired,
  openCategory: PropTypes.func.isRequired,
  openedCategory: PropTypes.string,
  toggleOrSetAlteration: PropTypes.func.isRequired,
  cloth: PropTypes.shape({}).isRequired,
};

export default Alterations;
