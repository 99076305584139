import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

import isMobile from '../services/isMobile';
import { trackEvent } from '../services/analytics';
import DaysSelector from '../components/Home/v2/DaysSelector';
import SlotsSelector from '../components/Home/v2/SlotsSelector';
import Dots from '../components/Home/v2/Dots';
import { margins, ButtonContainer } from '../tilli-components/src/components/styledComponents';
import { Button, Title2, Subtitle2, Content, ErrorText } from '../components/Home/v2/StyledComponents';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => !props.noMargin && `margin-top: ${!isMobile() ? margins.m : margins.s}`};
`;

const isEqualDays = (selectedDay, day) => selectedDay && selectedDay.isSame(day, 'day');

function getBeginHour(selectedDay) {
  const now = moment();
  const tomorrow = moment().add(1, 'day');
  if (isEqualDays(selectedDay, now)) return Math.max(13, now.hours() + 4);
  if (isEqualDays(selectedDay, tomorrow) && now.hours() > 18) return 13;
  return 9;
}

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { slots: {}, currentSelectedDay: undefined, submitError: false };
  }

  toggleSlot(hours, minutes) {
    if (this.state.currentSelectedDay !== undefined) {
      let selectedSlots = this.state.slots[this.state.currentSelectedDay.format()];
      const slotIndex = selectedSlots
        .findIndex(slot => slot.hours === hours && slot.minutes === minutes);
      if (slotIndex >= 0) {
        selectedSlots.splice(slotIndex, 1);
      } else {
        selectedSlots = [...selectedSlots, { hours, minutes }];
      }
      const slots = { ...this.state.slots };
      slots[this.state.currentSelectedDay.format()] = [...selectedSlots];
      this.setState({ slots, submitError: false });
      this.props.onChange(slots);
    }
  }

  toogleDay(day) {
    const slotKeyIndex = Object.keys(this.state.slots).findIndex(key => key === day.format());
    const slots = !(slotKeyIndex >= 0) ?
      { ...this.state.slots, [day.format()]: [] } :
      { ...this.state.slots };
    this.setState({
      slots,
      currentSelectedDay: day,
    });
  }

  isValid() {
    const { slots } = this.state;
    const fillSlots = Object.keys(slots).filter(key => slots[key].length !== 0);
    return fillSlots.length !== 0;
  }

  goNext() {
    if (this.isValid()) {
      trackEvent('click', 'suivant_block-step1');
      this.props.goNext();
    } else {
      this.setState({ submitError: true });
    }
  }

  render() {
    const { slots, currentSelectedDay } = this.state;
    const selectedSlots = currentSelectedDay ? slots[currentSelectedDay.format()] : [];
    const beginHour = getBeginHour(currentSelectedDay);
    return (
      <Content>
        <Column noMargin>
          <Title2>Rendez-vous</Title2>
          <Dots />
          <Subtitle2>Choisissez plusieurs<br />créneaux</Subtitle2>
        </Column>
        <Column>
          <DaysSelector
            viewsToShow={!isMobile() ? 9 : 4}
            onChange={day => this.toogleDay(day)}
            selectedDays={slots}
            currentSelectedDay={currentSelectedDay}
          />
          <Title2 margin={`${margins.m} 0px`}>
            Horaires disponibles <br />
            {currentSelectedDay && `Le ${currentSelectedDay.format('dddd DD MMMM')}`}
          </Title2>
          <SlotsSelector
            beginHour={beginHour}
            selectedSlots={selectedSlots}
            onChange={(hours, minutes) => this.toggleSlot(hours, minutes)}
          />
          <ButtonContainer>
            <Button navy onClick={() => this.goNext()}>
              Détailler ma commande
            </Button>
          </ButtonContainer>
          {this.state.submitError &&
            <ErrorText>choisissez au moins un créneau</ErrorText>
          }
        </Column>
      </Content>
    );
  }
}

Step1.propTypes = {
  goNext: PropTypes.func,
  onChange: PropTypes.func,
};

Step1.defaultProps = {
  goNext() {},
  onChange() {},
};

export default Step1;
