import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormInput, OrderSummaryText } from '../StyledComponents';

const Container = styled.div`width: 100%;`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const B = styled.span`
  font-weight: bold;
`;

const Span1 = styled.span`
  font-family: Roboto;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

class PromoCodeField extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { promoCode, errorPromoCode, submit } = this.props;
    return (
      <Container>
        <Row>
          <FormInput
            noMargin type="text" placeholder="Code promo" value={this.state.promoCodeIt || ''}
            onChange={e => this.setState({ promoCodeIt: e.target.value })}
            onBlur={() => submit(this.state.promoCodeIt)}
          />
          <Span1>Appliquer</Span1>
        </Row>
        {promoCode && promoCode.code &&
          <div>
            {promoCode.value ?
              <OrderSummaryText>
                <B>{promoCode.name}</B> vous offre <B>{promoCode.value}{promoCode.unit === 'EUROS' ? '€' : '%'}</B> sur
                cette commande.
                {promoCode.brand && promoCode.brand.name &&
                  <span>
                    <br /> (code valide uniquement sur les retouches des vêtements de la marque
                    ${promoCode.brand.name} et non applicable sur les éventuels frais de livraison)
                  </span>
                }
              </OrderSummaryText>
              : `Le code ${promoCode.name} a bien été pris en compte pour cette commande.`
            }
          </div>
        }
        {errorPromoCode && !this.state.promoCode &&
          <OrderSummaryText error >code non reconnu</OrderSummaryText>
        }
      </Container>
    );
  }
}

PromoCodeField.propTypes = {
  promoCode: PropTypes.shape({}),
  errorPromoCode: PropTypes.bool,
  submit: PropTypes.func.isRequired,
};

PromoCodeField.defaultProps = {
  promoCode: null,
  errorPromoCode: false,
};

export default PromoCodeField;
