import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FacebookLogin from 'react-facebook-login';
import ReactTooltip from 'react-tooltip';
import { navigate } from 'gatsby';

import routes from '../../../Routes';
import CreditCardForm from './order/CreditCardForm';
import isMobile from '../../../services/isMobile';
import checkZipcodeError, { getLocality } from '../../../services/zipcode';
import { trackEvent } from '../../../services/analytics';
import LoginPopup from './LoginPopup';
import { margins, colors, Title2, Subtitle2, ButtonContainer, Button, FormInput,
  OrderSummaryText, mobileThresholdPixels, ErrorText } from '../../../components/Home/v2/StyledComponents';

const inputs = {
  firstname: { name: 'firstname', placeholder: 'prénom*', isRequired: true },
  lastname: { name: 'lastname', placeholder: 'nom*', isRequired: true },
  phone: { name: 'phone', placeholder: 'téléphone*', isRequired: true },
  email: { name: 'email', placeholder: 'email*', isRequired: true },
  password: { name: 'password', placeholder: 'mot de passe*', isRequired: true, type: 'password' },
  passwordCheck: { name: 'passwordCheck', placeholder: 'confirmer mot de passe*', isRequired: true, type: 'password' },
  street: { name: 'street', placeholder: 'adresse*', isRequired: true },
  comment: { name: 'comment', placeholder: 'code, interphone, escalier, étage, etc.', isRequired: false },
  zipcode: { name: 'zipcode', placeholder: 'code postal*', isRequired: true },
  locality: { name: 'locality', placeholder: 'ville*', isRequired: true },
  cardNumber: { name: 'cardNumber', placeholder: 'numéro de carte*', isRequired: true },
  expiryDate: { name: 'expiryDate', placeholder: 'date d’expiration (MM/AA)*', isRequired: true },
  cvc: { name: 'cvc', placeholder: 'CVV*', isRequired: true },
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => !props.flexStart && 'align-items: center'};
  ${props => props.margin && `margin: ${props.margin}`};
  font-family: Roboto;
`;

const ButtonRow = styled(Row)`
  align-items: flex-start;
  margin: ${margins.l} 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px 0px 0px;
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.center && 'align-items: center'};
`;

const StyledButton = styled(Button)`
  width: 245px;
  height: 50px;
  padding: 0px;
  border-width: 1px;
  text-transform: none;
  font-size: 11px;
  font-weight: normal;
  ${props => props.facebook && `
    background-color: ${colors.lightBlue};
    color: ${colors.white};
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 30px);
  }
`;

const StyledSubTitle2 = styled(Subtitle2)`
  text-align: left;
  margin-top: ${margins.l};
`;

const A = styled.a`
  text-align: center;
  margin-top: 5px;
  font-size: ${props => props.inherit ? 'inherit' : '10px'};
  ${props => props.inherit && 'text-decoration: underline;'};
  cursor: pointer;
`;

const TextContainer = styled(OrderSummaryText)`
  ${props => !props.noMargin && `margin-top: ${margins.s}`};
  ${props => props.alignRight && 'text-align: right'};
  ${props => props.bold && `
    letter-spacing: 1px;
    font-weight: bold;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

class InfosForm extends React.Component {
  constructor() {
    super();
    const { firstname, lastname, phone, email, password, passwordCheck } = {};
    const { street, comment, zipcode, locality } = {};
    const { cardNumber, expiryDate, cvc } = {};
    let customerState = { firstname, lastname, phone, email, password, passwordCheck };
    customerState = { ...customerState, street, comment, zipcode, locality };
    const customerCard = { cardNumber, expiryDate, cvc };
    const formValues = { ...customerState, ...customerCard };
    this.state = { formValues, fillRequest: false, showLoginPopup: false };

    this.onChange = this.onChange.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.isFieldValid = this.isFieldValid.bind(this);
    this.isFieldDisabled = this.isFieldDisabled.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.initState(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.initState(newProps);
  }

  onChange(field, value) {
    const formValues = this.state.formValues;
    if (field === 'zipcode') {
      formValues.locality = getLocality(value);
      inputs.locality.disabled = getLocality(value);
      this.props.onZipcodeChange(value);
    }
    formValues[field] = value;
    this.setState({ formValues });
  }

  onChangeEvent(event) {
    const field = event.target.name;
    const value = event.target.value;
    this.onChange(field, value);
  }

  initState(props) {
    const { formValues } = this.state;
    const { customer } = props;
    const { firstname, lastname, phone, email, password, passwordCheck, cards } = customer || formValues;
    const { street, comment, zipcode, locality } = customer ?
      customer.address || (customer.addresses && customer.addresses[0]) || {} :
      formValues;
    const { cardNumber, expiryDate, cvc } = formValues;
    const selectedCardId = cards && cards[0] && cards[0].id;
    let customerState = { firstname, lastname, phone, email, password, passwordCheck };
    customerState = { ...customerState, street, comment, zipcode, locality };
    const customerCard = { cardNumber, expiryDate, cvc, selectedCardId };
    const fillRequest = customer ? true : this.state.fillRequest;
    this.setState({ formValues: { ...customerState, ...customerCard }, fillRequest });
  }

  isFieldValid(input) {
    const { formValues } = this.state;
    const { customer, showPayment } = this.props;
    const field = input.name;
    const value = formValues[field];
    const fillRequire = input.isRequired && !value && this.state.fillRequest;
    switch (field) {
      case 'zipcode': {
        if (checkZipcodeError(value) && value && value.length === 5) trackEvent('zipcode-error', value);
        return !fillRequire && !(checkZipcodeError(value) && this.state.fillRequest);
      }
      case 'password':
      case 'passwordCheck':
        return customer || (!fillRequire &&
          (formValues.password === formValues.passwordCheck || !this.state.fillRequest));
      case 'firstname':
      case 'lastname':
      case 'phone':
      case 'email':
      case 'street':
      case 'comment':
      case 'locality':
        return !fillRequire;
      case 'cardNumber':
      case 'expiryDate':
      case 'cvc': {
        if (showPayment) return this.state.formValues.selectedCardId ? true : !fillRequire;
        return true; // card not mandatory to order for new customers
      }
      default:
        throw new Error('unknown field');
    }
  }

  isFieldDisabled(input) {
    let disabled = input.disabled || this.props.customer;
    if (['phone', 'zipcode'].includes(input.name)) disabled = false;
    if (input.name === 'locality') disabled = input.disabled;
    return disabled;
  }

  isValid() {
    const invalidFields = Object.values(inputs).filter(input => !this.isFieldValid(input));
    return invalidFields.length === 0;
  }

  submit() {
    this.setState({ fillRequest: true }, () => {
      if (this.isValid()) {
        this.props.submitForm(this.state.formValues, this.state.formValues.selectedCardId);
      }
    });
  }

  InputsRow(input0, input1) {
    if (input0.name === 'password' && this.props.customer) return null;
    const { formValues } = this.state;
    return (
      <div>
        <Row>
          <FormInput
            name={input0.name} value={formValues[input0.name] || ''}
            type={input0.type || 'text'}
            disabled={this.isFieldDisabled(input0)}
            placeholder={input0.placeholder}
            onChange={this.onChangeEvent}
            error={!this.isFieldValid(input0)}
          />
          <FormInput
            name={input1.name} value={formValues[input1.name] || ''}
            type={input1.type || 'text'}
            disabled={this.isFieldDisabled(input1)}
            placeholder={input1.placeholder}
            onChange={this.onChangeEvent}
            error={!this.isFieldValid(input1)}
          />
        </Row>
        {input0.name === 'zipcode' && !this.isFieldValid(input0) &&
          <OrderSummaryText error>{checkZipcodeError(formValues[input0.name])}</OrderSummaryText>
        }
      </div>
    );
  }

  render() {
    const { submitLogin, submitFacebookLogin, errorOrder, showPayment } = this.props;
    const { showLoginPopup } = this.state;
    return (
      <div>
        <ReactTooltip />
        {!isMobile() && <Title2>Informations</Title2>}
        <ButtonRow>
          <Column>
            <StyledButton
              onClick={() => {
                trackEvent('click', 'login_block-step4');
                this.setState({ showLoginPopup: true });
              }}
            >
              se connecter
            </StyledButton>
            <A
              onClick={() => {
                trackEvent('click', 'mot-passe-oublie_block-step4');
                navigate(routes.ForgottenPasswordRequest.url);
              }}
            >
              mot de passe oublié ?
            </A>
          </Column>
          <FacebookLogin
            appId="258420877937846"
            autoLoad={false}
            fields="first_name,last_name,email"
            callback={submitFacebookLogin}
            textButton="continuer avec Facebook"
            cssClass="kep-login-facebook facebook_button"
            isMobile
            disableMobileRedirect
          />
        </ButtonRow>
        <Column>
          <StyledSubTitle2>Coordonnées</StyledSubTitle2>
          {this.InputsRow(inputs.firstname, inputs.lastname)}
          {this.InputsRow(inputs.phone, inputs.email)}
          {this.InputsRow(inputs.password, inputs.passwordCheck)}
          <FormInput
            full name={inputs.street.name}
            placeholder={inputs.street.placeholder}
            value={this.state.formValues[inputs.street.name] || ''}
            onChange={this.onChangeEvent}
            error={!this.isFieldValid(inputs.street)}
          />
          <FormInput
            full name={inputs.comment.name}
            placeholder={inputs.comment.placeholder}
            value={this.state.formValues[inputs.comment.name] || ''}
            onChange={this.onChangeEvent}
            error={!this.isFieldValid(inputs.comment)}
          />
          {this.InputsRow(inputs.zipcode, inputs.locality)}
        </Column>
        {showPayment &&
          <Column>
            <StyledSubTitle2>Paiement</StyledSubTitle2>
            <Row>
              <TextContainer>
                Vous serez débité du montant exact de votre commande {!isMobile() && <br />}
                après votre premier rendez-vous avec le couturier.
              </TextContainer>
              <TextContainer alignRight>
                <A
                  inherit
                  onClick={this.props.goToEstimate}
                  data-tip="Votre Tilliste fera l'esimation lors du RDV"
                >estimé en rendez-vous</A><br />(facultatif)
              </TextContainer>
            </Row>
            <CreditCardForm
              inputs={inputs}
              customer={this.props.customer}
              formValues={this.state.formValues}
              onChange={this.onChange}
              isFieldValid={this.isFieldValid}
              fillRequest={this.state.fillRequest}
            />
          </Column>
        }
        <Column center>
          {this.props.isLoading ?
            <ButtonContainer>
              <Button>Chargement…</Button>
            </ButtonContainer> :
            <ButtonContainer>
              <Button navy onClick={() => this.submit()}>Valider ma commande</Button>
            </ButtonContainer>
          }
          {errorOrder && <ErrorText>{errorOrder}</ErrorText>}
        </Column>
        <LoginPopup
          closeRequest={request => this.setState({ showLoginPopup: !request })}
          show={!this.props.customer && showLoginPopup}
          submit={({ email, password }) => submitLogin({ email, password })}
        />
      </div>
    );
  }
}

InfosForm.propTypes = {
  customer: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showPayment: PropTypes.bool,
  submitForm: PropTypes.func,
  submitLogin: PropTypes.func,
  submitFacebookLogin: PropTypes.func,
  goToEstimate: PropTypes.func,
  onZipcodeChange: PropTypes.func,
  isLoading: PropTypes.bool,
  errorOrder: PropTypes.string,
};

InfosForm.defaultProps = {
  customer: undefined,
  showPayment: false,
  submitForm() {},
  submitLogin() {},
  submitFacebookLogin() {},
  goToEstimate() {},
  onZipcodeChange() {},
  isLoading: false,
  errorOrder: null,
};

export default InfosForm;
