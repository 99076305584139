import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InfosForm from '../components/Home/v2/InfosForm';
import OrderSummary from '../components/Home/v2/OrderSummary';
import { colors, margins, Content, mobileThresholdPixels } from '../components/Home/v2/StyledComponents';

import backgroundImg from '../assets/order/recapBackground.jpg';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
  }
`;

const SideContainer = styled.div`
  background-color: ${colors.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${props => props.background && `
    background-image: url(${backgroundImg});
    background-repeat: no-repeat;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    min-height: unset;
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  margin: ${margins.xl} ${margins.l} ${margins.m};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
  }
`;

const RightContainer = styled.div`
  margin: ${margins.l};
  @media (max-width: ${mobileThresholdPixels}) {
   margin: ${margins.l} ${margins.s};
  }
`;

const Step4 = ({ customer, errorLogin, deliveryFee, minOrderAmount, minOrderAmountFee,
  promoCode, errorPromoCode, total, submitForm, submitLogin, submitFacebookLogin,
  submitPromoCode, goToEstimate, slots, isLoading, totalClothes, errorOrder, showPayment,
  onZipcodeChange,
}) => (
  <Content lightGrey>
    <Row columnOnMobile>
      <SideContainer>
        <LeftContainer>
          <InfosForm
            errorLogin={errorLogin}
            customer={customer}
            showPayment={showPayment}
            submitForm={submitForm}
            submitLogin={submitLogin}
            submitFacebookLogin={submitFacebookLogin}
            isLoading={isLoading}
            goToEstimate={goToEstimate}
            errorOrder={errorOrder}
            onZipcodeChange={onZipcodeChange}
          />
        </LeftContainer>
      </SideContainer>

      <SideContainer background>
        <RightContainer>
          <OrderSummary
            slots={slots}
            deliveryFee={deliveryFee}
            minOrderAmount={minOrderAmount}
            minOrderAmountFee={minOrderAmountFee}
            promoCode={promoCode}
            errorPromoCode={errorPromoCode}
            total={total}
            totalClothes={totalClothes}
            submit={submitPromoCode}
            goToEstimate={goToEstimate}
          />
        </RightContainer>
      </SideContainer>
    </Row>
  </Content>
);

Step4.propTypes = {
  customer: PropTypes.shape({}),
  showPayment: PropTypes.bool,
  errorLogin: PropTypes.bool,
  deliveryFee: PropTypes.number.isRequired,
  minOrderAmount: PropTypes.number.isRequired,
  minOrderAmountFee: PropTypes.number.isRequired,
  promoCode: PropTypes.shape({}),
  errorPromoCode: PropTypes.bool,
  total: PropTypes.number.isRequired,
  totalClothes: PropTypes.number.isRequired,
  submitForm: PropTypes.func,
  submitLogin: PropTypes.func,
  submitFacebookLogin: PropTypes.func,
  submitPromoCode: PropTypes.func,
  goToEstimate: PropTypes.func,
  onZipcodeChange: PropTypes.func,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  errorOrder: PropTypes.string,
};

Step4.defaultProps = {
  customer: null,
  showPayment: false,
  errorLogin: true,
  promoCode: {},
  errorPromoCode: false,
  submitForm() {},
  submitLogin() {},
  submitFacebookLogin() {},
  submitPromoCode() {},
  goToEstimate() {},
  onZipcodeChange() {},
  slots: [],
  isLoading: false,
  errorOrder: null,
};

export default Step4;
