import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, margins } from '../../../../components/Home/v2/StyledComponents';

const Container = styled.div`
  border: solid 1px ${colors.navy};
  color: ${colors.navy};
  cursor: pointer;
  font: 400 11px system-ui;
  ${props => !props.isLast && 'border-bottom-width:0px;'}
  ${props => !props.isFirst && 'border-top-width:0px;'}
`;

const Content = styled.div`
  padding: ${margins.s} 0px;
  margin: 0px ${margins.s};
  border: solid 0px ${colors.navy};
  border-top-width: ${props => props.isFirst ? '0px' : '1px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CreditCardRow = ({ children, isFirst, isLast, onClick }) => (
  <Container isFirst={isFirst} isLast={isLast} onClick={onClick}>
    <Content isFirst={isFirst}>
      {children}
    </Content>
  </Container>
);

CreditCardRow.propTypes = {
  children: PropTypes.node.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

CreditCardRow.defaultProps = {
  isFirst: false,
  isLast: false,
  onClick() {},
};

export default CreditCardRow;
