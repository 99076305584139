import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobileThresholdPixels, fontSizes, margins, colors, Button } from './StyledComponents';

import img1 from '../../../assets/order/fabrics/silk.jpg';
import img2 from '../../../assets/order/fabrics/lace.jpg';
import img3 from '../../../assets/order/fabrics/leather.jpg';
import img4 from '../../../assets/order/fabrics/stitch.jpg';
import img5 from '../../../assets/order/fabrics/embroidery.jpg';
import img6 from '../../../assets/order/fabrics/jeans.jpg';
import img7 from '../../../assets/order/fabrics/suit.jpg';
import img8 from '../../../assets/order/fabrics/curtain.jpg';

const fabrics = [
  { name: 'Soie ou mousseline', slug: 'silk', img: img1 },
  { name: 'Dentelle', slug: 'lace', img: img2 },
  { name: 'Cuir', slug: 'leather', img: img3 },
  { name: 'Maille', slug: 'stitch', img: img4 },
  { name: 'Broderie', slug: 'embroidery', img: img5 },
  { name: 'Jeans', slug: 'jeans', img: img6 },
  { name: 'Tailleur', slug: 'suit', img: img7 },
  { name: 'Rideaux', slug: 'curtain', img: img8 },
];

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: ${margins.m} 0px ${margins.s};
`;

const BlockContainer = styled.div`
  background-color: ${colors.white};
  width: 280px;
  text-align: center;
  overflow: hidden;
  margin-bottom: ${margins.s} ;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 165px;
  }
`;

const Img = styled.img`
  width: inherit;
`;

const ButtonContainer = styled.div`
  padding: ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
  }
`;

const StyledButton = styled(Button)`
  font-size: ${fontSizes.s};
  padding: 12px 54px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
    margin: ${margins.xs};
    padding: 0px;
    min-width: 150px;
    min-height: 40px;
  }
`;

const TextContainer = styled.div`
  font-family: Roboto;
  text-align: left;
  padding: ${margins.s} ${margins.s} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    padding: ${margins.xs} ${margins.xs} 0px;
  }
`;

const FabricsSelector = ({ toggle, selectedFabrics }) => (
  <Row>
    {fabrics.map(fabric =>
      <BlockContainer key={fabric.slug}>
        <Img src={fabric.img} alt={fabric.name} />
        <TextContainer>{fabric.name}</TextContainer>
        <ButtonContainer>
          <StyledButton
            onClick={() => toggle(fabric.slug)}
            navy={selectedFabrics.findIndex(fabricIt => fabricIt === fabric.slug) >= 0}
          >
            Sélectionner
          </StyledButton>
        </ButtonContainer>
      </BlockContainer>,
    )}
  </Row>
);

FabricsSelector.propTypes = {
  selectedFabrics: PropTypes.arrayOf(PropTypes.string),
  toggle: PropTypes.func,
};

FabricsSelector.defaultProps = {
  selectedFabrics: [],
  toggle() {},
};

export default FabricsSelector;
